'use strict';

angular.module('isahomeApp')
    .controller('UserManagementController', function ($scope, $state, Principal, User, UserSearch, ParseLinks, paginationConstants, pagingParams) {
        $scope.users = [];
        $scope.authorities = ['ROLE_USER', 'ROLE_ADMIN'];

        $scope.page = pagingParams.page;
        $scope.predicate = pagingParams.predicate;
        $scope.reverse = pagingParams.ascending;
        $scope.searchQuery = pagingParams.search;
        $scope.itemsPerPage = paginationConstants.itemsPerPage;
        $scope.currentSearch = pagingParams.search;
        Principal.identity().then(function(account) {
            $scope.currentAccount = account;
        });
        $scope.loadAll = function () {
            if (pagingParams.search) {
                UserSearch.query({
                    query: $scope.currentSearch + '*',
                    page: $scope.page - 1,
                    size: paginationConstants.itemsPerPage,
                }, onSuccess, onError);
            } else {
                User.query({page: $scope.page - 1, size: paginationConstants.itemsPerPage, sort: sort()}, onSuccess, onError);
            }

        };
        function sort() {
            var result = [$scope.predicate + ',' + ($scope.reverse ? 'asc' : 'desc')];
            if ($scope.predicate !== 'id') {
                result.push('id');
            }
            return result;
        }
        function onError(data) {
            console.log(data)
        }
        function onSuccess(data, headers) {
            $scope.links = ParseLinks.parse(headers('link'));
            $scope.totalItems = headers('X-Total-Count');
            $scope.queryCount = $scope.totalItems;
            $scope.users = data;
            $scope.page = pagingParams.page;
        }

        $scope.loadPage = function (page) {
            $scope.page = page;
            $scope.loadAll();
        };
        $scope.loadAll();

        $scope.setActive = function (user, isActivated) {
            user.activated = isActivated;
            User.update(user, function () {
                $scope.loadAll();
                $scope.clear();
            });
        };

        $scope.clear = function () {
            $scope.user = {
                id: null, login: null, firstName: null, lastName: null, email: null,
                activated: null, langKey: null, createdBy: null, createdDate: null,
                lastModifiedBy: null, lastModifiedDate: null, resetDate: null,
                resetKey: null, authorities: null
            };
            if ($scope.editForm) {
                $scope.editForm.$setPristine();
                $scope.editForm.$setUntouched();
            }
            $scope.links = null;
            $scope.page = 1;
            $scope.predicate = 'id';
            $scope.reverse = true;
            $scope.currentSearch = null;
            $scope.transition();
        };

        $scope.transition = function() {
            $state.transitionTo($state.$current, {
                page: $scope.page,
                sort: $scope.predicate + ',' + ($scope.reverse ? 'asc' : 'desc'),
                search: $scope.currentSearch
            });
        }

        $scope.search = function (searchQuery) {
            if (!searchQuery){
                return $scope.clear();
            }
            $scope.links = null;
            $scope.page = 1;
            $scope.predicate = '_score';
            $scope.reverse = false;
            $scope.currentSearch = searchQuery;
            $scope.transition();
        }

    });
