(function() {
    'use strict';

    angular
        .module('isahomeApp')
        .controller('FileDownloadController', FileDownloadController);

    FileDownloadController.$inject = ['$scope', '$rootScope', 'deviceDetector', '$window', '$timeout', '$stateParams', 'DataUtils', 'entity', 'FileUpload', 'FileSaver'];

    function FileDownloadController($scope, $rootScope, deviceDetector, $window, $timeout, $stateParams, DataUtils, entity, FileUpload, FileSaver) {
        var vm = this;
        vm.fileUpload = entity;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;
        FileUpload.get({id: $stateParams.id}, function(result){
            if(result.attachmentContentType === 'application/pdf' && deviceDetector.browser != 'ie'){
                DataUtils.openFile(result.attachmentContentType, result.attachment);
            }else{
                var blob = DataUtils.toBlob(result.attachment, result.attachmentContentType);
                FileSaver.saveAs(blob, result.name);
            }
            if(deviceDetector.browser != 'ie'){
                $timeout($window.close, 100);
            }
        });

    }
})();
