'use strict';

angular.module('isahomeApp')
    .controller('DirectoryImportController', function ($scope, UserProfile, $localStorage, $sce, $stateParams, Principal, LoginService, PageContentByName, PageContent, CarouselImage, $state) {
        $scope.gridOptions = {};

        $scope.reset = reset;

        $scope.createDirectoryEntries = function(){
            console.log($scope.gridOptions.data);
            angular.forEach($scope.gridOptions.data, function(person, i){
                var p = {};
                p.addressOne = person['Address 1'];
                p.addressTwo = person['Address 2'];
                p.firstName = person['First Name'];
                p.lastName = person['Last Name'];
                p.middleInitial = person['M.I.'];
                p.prefix = person['Prefix'];
                p.degree = person['Degree'];
                p.city = person['City'];
                p.state = person['State'];
                p.zipCode = person['Zip Code'];
                p.country = person['Country'];
                p.phone = person['Phone #'];
                p.fax = person['FAX #'];
                p.email = person['Email address'];
                p.institution = person['Institution'];
                p.paidThrough = person['Member Paid Through'];
                //todo prevent duplicates
                UserProfile.update(p, function(data){
                        $scope.gridOptions.data.pop(i);
                        if($scope.gridOptions.data.length == 0){
                            $state.go('directory')
                        }
                    }
                );

            });
        };

        function reset() {
            $scope.gridOptions.data = [];
            $scope.gridOptions.columnDefs = [];
        }
    })


    .directive("fileread", [function () {
    return {
        scope: {
            opts: '='
        },
        link: function ($scope, $elm, $attrs) {
            $elm.on('change', function (changeEvent) {
                var reader = new FileReader();

                reader.onload = function (evt) {
                    $scope.$apply(function () {
                        var data = evt.target.result;

                        var workbook = XLSX.read(data, {type: 'binary'});

                        var headerNames = XLSX.utils.sheet_to_json( workbook.Sheets[workbook.SheetNames[0]], { header: 1 })[0];

                        var data = XLSX.utils.sheet_to_json( workbook.Sheets[workbook.SheetNames[0]]);
                        $scope.opts.columnDefs = [];
                        headerNames.forEach(function (h) {
                            $scope.opts.columnDefs.push({ field: h });
                        });

                        $scope.opts.data = data;

                        $elm.val(null);
                    });
                };

                reader.readAsBinaryString(changeEvent.target.files[0]);
            });
        }
    }
}]);
