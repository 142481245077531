(function() {
    'use strict';

    angular
        .module('isahomeApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('registration', {
            parent: 'entity',
            url: '/registration?page&sort&search',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'Registrations'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/registration/registrations.html',
                    controller: 'RegistrationController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
            }
        })
        .state('registration-detail', {
            parent: 'entity',
            url: '/registration/{id}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'Registration'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/registration/registration-detail.html',
                    controller: 'RegistrationDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                entity: ['$stateParams', 'Registration', function($stateParams, Registration) {
                    return Registration.get({id : $stateParams.id});
                }]
            }
        })
        .state('registration.new', {
            parent: 'registration',
            url: '/new',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/registration/registration-dialog.html',
                    controller: 'RegistrationDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                firstname: null,
                                middle: null,
                                lastname: null,
                                position: null,
                                subspecialty: null,
                                addressOne: null,
                                addressTwo: null,
                                city: null,
                                state: null,
                                zip: null,
                                country: null,
                                phone: null,
                                fax: null,
                                email: null,
                                referenceOne: null,
                                referenceOneCountry: null,
                                referenceTwo: null,
                                referenceTwoCountry: null,
                                certificateName: null,
                                institution: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('registration', null, { reload: true });
                }, function() {
                    $state.go('registration');
                });
            }]
        })
        .state('registration.guest', {
            parent: 'app',
            url: '/register',
            data: {
                pageTitle: 'Register'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/registration/registration-dialog.html',
                    controller: 'RegistrationDialogNewController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                entity: function () {
                    return {
                        firstname: null,
                        middle: null,
                        lastname: null,
                        position: null,
                        subspecialty: null,
                        mailingAddress: null,
                        street: null,
                        city: null,
                        state: null,
                        zip: null,
                        country: null,
                        phone: null,
                        fax: null,
                        email: null,
                        referenceOne: null,
                        referenceOneCountry: null,
                        referenceTwo: null,
                        referenceTwoCountry: null,
                        certificateName: null,
                        id: null
                    };
                }
            }
        })
        .state('registration-success', {
            parent: 'entity',
            url: '/register/success',
            data: {
                pageTitle: 'Registration Success'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/registration/registration-success.html',
                }
            }
        })
        .state('registration.edit', {
            parent: 'registration',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/registration/registration-dialog.html',
                    controller: 'RegistrationDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Registration', function(Registration) {
                            return Registration.get({id : $stateParams.id});
                        }]
                    }
                }).result.then(function() {
                    $state.go('registration', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('registration.delete', {
            parent: 'registration',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/registration/registration-delete-dialog.html',
                    controller: 'RegistrationDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['Registration', function(Registration) {
                            return Registration.get({id : $stateParams.id});
                        }]
                    }
                }).result.then(function() {
                    $state.go('registration', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
