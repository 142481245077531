(function() {
    'use strict';

    angular
        .module('isahomeApp')
        .factory('CommentSearch', CommentSearch);

    CommentSearch.$inject = ['$resource'];

    function CommentSearch($resource) {
        return $resource('api/_search/comments', {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
