'use strict';

angular.module('isahomeApp')
    .controller('AdminFlipbooksController', function ($scope, $state, $http, SiteHeader, MainSiteHeader) {
        $scope.sites = [];
        $http.get('http://flipbooks.isahome.org').success(function(test){
            var output = test;
            var tableStart = test.indexOf('<table>');
            var tableEnd = test.indexOf('</table>');
            output = output.substr(tableStart+7, tableEnd-tableStart-7);
            var patt = /href="(.*?)"/g;
            var match = "";
            while(match = patt.exec(output)){
                if(match[0][6] != '?'){
                    var plainUrl = match[0].substr(6);
                    $scope.sites.push(plainUrl.substr(0, plainUrl.length-1));
                }
            }
        })
    });
