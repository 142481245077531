(function() {
    'use strict';

    angular
        .module('isahomeApp')
        .controller('RegistrationSuccessController', RegistrationSuccessController);

    RegistrationSuccessController.$inject = ['$scope', '$state', '$stateParams', 'entity', 'Registration'];

    function RegistrationSuccessController ($scope, $state, $stateParams, entity, Registration) {
        var vm = this;
        vm.registration = entity;

        $timeout(function(){
           $state.go('page', {name: 'Pay Dues'})
        },6000)
    }
})();
