'use strict';

angular.module('isahomeApp')
    .factory('PageComment', function ($resource, DateUtils) {
        return $resource('api/pageComments/:id', {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    data.commentDate = DateUtils.convertDateTimeFromServer(data.commentDate);
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    })
    .factory('PageCommentApproval', function ($resource, DateUtils) {
        return $resource('api/pageComments/approval/:isApproved', {}, {
            'query': { method: 'GET', isArray: true},
        });
    })
    .factory('PageCommentApprovalUpdate', function ($resource, DateUtils) {
        return $resource('api/pageComments/approval/:id/:status', {}, {
            'update': { method:'PUT' },
        });
    }).factory('PageCommentByName', function ($resource, DateUtils) {
        return $resource('api/pageComments/page/:name', {}, {
            'query': { method: 'GET', isArray: true},
            'create': { method:'POST' },
            'update': { method:'PUT' },
            'get': {
                method: 'GET',
                //transformResponse: function (data) {
                //    data = angular.fromJson(data);
                //    data.lastUpdate = DateUtils.convertDateTimeFromServer(data.lastUpdate);
                //    return data;
                //}
            }
        });
    });
