(function() {
    'use strict';

    angular
        .module('isahomeApp')
        .factory('AttachmentSearch', AttachmentSearch);

    AttachmentSearch.$inject = ['$resource'];

    function AttachmentSearch($resource) {
        return $resource('api/_search/attachments', {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
