'use strict';

angular.module('isahomeApp')
    .config(function ($stateProvider) {
        $stateProvider
            .state('flipbooks', {
                parent: 'adminCenter',
                url: '/flipbooks',
                data: {
                    authorities: ['ROLE_ADMIN'],
                    pageTitle: 'Flipbook Management'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/admin/center/flipbooks/admin.flipbooks.html',
                        controller: 'AdminFlipbooksController'
                    }
                }
            })
    });
