(function() {
    'use strict';

    angular
        .module('isahomeApp')
        .factory('Attachment', Attachment)
        .factory('AttachmentByCase', AttachmentByCase);

    Attachment.$inject = ['$resource', 'DateUtils'];
    AttachmentByCase.$inject = ['$resource', 'DateUtils'];

    function Attachment ($resource, DateUtils) {
        return $resource('api/attachments/:id', {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }
    function AttachmentByCase ($resource, DateUtils) {
        return $resource('api/attachments/case/:id', {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
