(function() {
    'use strict';

    angular
        .module('isahomeApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('case-study', {
            parent: 'entity',
            url: '/case-study?page&sort&search',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'CaseStudies'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/case-study/case-studies.html',
                    controller: 'CaseStudyController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
            }
        })
        .state('case-study-detail', {
            parent: 'entity',
            url: '/case-study/{id}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'CaseStudy'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/case-study/case-study-detail.html',
                    controller: 'CaseStudyDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                entity: ['$stateParams', 'CaseStudy', function($stateParams, CaseStudy) {
                    return CaseStudy.get({id : $stateParams.id});
                }]
            }
        })
        .state('case-study.new', {
            parent: 'case-study',
            url: '/new',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/case-study/case-study-dialog.html',
                    controller: 'CaseStudyDialogController',
                    controllerAs: 'vm',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                title: null,
                                descr: null,
                                descr2: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('case-study', null, { reload: true });
                }, function() {
                    $state.go('case-study');
                });
            }]
        })
        .state('case-study.edit', {
            parent: 'case-study',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/case-study/case-study-dialog.html',
                    controller: 'CaseStudyDialogController',
                    controllerAs: 'vm',
                    size: 'lg',
                    resolve: {
                        entity: ['CaseStudy', function(CaseStudy) {
                            return CaseStudy.get({id : $stateParams.id});
                        }]
                    }
                }).result.then(function() {
                    $state.go('case-study', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('case-study.delete', {
            parent: 'case-study',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/case-study/case-study-delete-dialog.html',
                    controller: 'CaseStudyDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['CaseStudy', function(CaseStudy) {
                            return CaseStudy.get({id : $stateParams.id});
                        }]
                    }
                }).result.then(function() {
                    $state.go('case-study', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
