(function() {
    'use strict';

    angular
        .module('isahomeApp')
        .controller('SiteHeaderDetailController', SiteHeaderDetailController);

    SiteHeaderDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'SiteHeader', 'PageContent'];

    function SiteHeaderDetailController($scope, $rootScope, $stateParams, entity, SiteHeader, PageContent) {
        var vm = this;
        vm.siteHeader = entity;
        vm.load = function (id) {
            SiteHeader.get({id: id}, function(result) {
                vm.siteHeader = result;
            });
        };
        var unsubscribe = $rootScope.$on('isahomeApp:siteHeaderUpdate', function(event, result) {
            vm.siteHeader = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
