'use strict';

angular.module('isahomeApp')
    .config(function ($stateProvider) {
        $stateProvider
            .state('adminCenter', {
                parent: 'admin',
                url: '/center',
                data: {
                    authorities: ['ROLE_ADMIN'],
                    pageTitle: 'Admin Center'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/admin/center/admin.center.html',
                        controller: 'AdminCenterController'
                    }
                }
            })
            .state('searchReset', {
                parent: 'admin',
                url: '/search-reset',
                data: {
                    authorities: ['ROLE_ADMIN'],
                    pageTitle: 'Search Reset'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/admin/center/search.reset.html',
                        controller: 'SearchResetController'
                    }
                }
            });
    });
