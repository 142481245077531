'use strict';

angular.module('isahomeApp')
    .config(function ($stateProvider) {
        $stateProvider
            .state('admin', {
                abstract: true,
                parent: 'app'
            });
    });
