'use strict';

angular.module('isahomeApp')
    .factory('ContactForm', ContactForm)
    .controller('PageController', function ($scope, ContactForm, $localStorage, $stateParams, PageContent, PageContentByName, $state, $sce, Principal, LoginService, PageCommentByName) {
        $scope.pageName = $stateParams.name;
        $scope.comments = [];
        $scope.commentsEnabled = false
        $scope.showCommentForm = false;
        $scope.showSuccessMessage = false;
        $scope.$storage = $localStorage;
        $scope.isEditing = false;
        $scope.state = $state;
        $scope.message = {};
        $scope.showForm = true;
        $scope.successMsg = false;

        Principal.identity().then(function(account) {
            $scope.isLoggedIn = Principal.isAuthenticated();
            $scope.isAdmin = Principal.hasAuthority('ROLE_ADMIN');
        });
        $scope.login = function () {
            LoginService.open();
        };

        if($scope.pageName == 'case-presentations'){
            $state.go('cases');
            return null;
        }
        if($scope.pageName == 'Member Directory'){
            $state.go('directory');
            return null;
        }

        PageContentByName.get({name:$scope.pageName}, function(result){
            $scope.pageContent = result;
            if ($scope.pageContent.content.indexOf('<logged-in-only') !== -1) {
                if (!$scope.isLoggedIn) {
                    $scope.login();
                    $scope.pageContent.content = '';
                }
            }
            if ($scope.pageContent.content.indexOf('<page-comments') !== -1) {
                if ($scope.isLoggedIn && $scope.isAdmin) {
                    $scope.commentsEnabled = true;
                    $scope.reloadComments()
                }
            }
            if($scope.pageContent.siteHeaders.length > 0){
                var header = $scope.pageContent.siteHeaders[0];
                $scope.topHeaderName = header.topHeaderName;
                $scope.topHeaderLinkName = header.topHeaderLinkName;
                $scope.mainHeaderName = header.mainHeaderName;
                $scope.mainHeaderLinkName = header.mainHeaderLinkName;
                $scope.subHeaderName = header.name;
                $scope.subHeaderLinkName = header.targetPageLinkName;

                var topName = header.topHeaderName || header.mainHeaderName;
                angular.forEach($scope.$storage.headers, function(header){
                    if(header.name == topName){
                        $scope.topHeader = header;
                    }
                })
            }else{
                $scope.mainHeaderName = $scope.pageContent.title;
                $scope.mainHeaderLinkName = $scope.pageContent.linkName;
            }
        }, function(error){
            $scope.pageContent = error;
            $scope.pageContent.content = $sce.trustAsHtml('<h3>No Page Found</h3>');

        });
        $scope.edit = function(){
            $scope.isEditing = true;
        };
        var onSaveSuccess = function (result) {
            $scope.$emit('isahomeApp:pageContentUpdate', result);
            $scope.isSaving = false;
            $scope.isEditing = false;
            $state.go('page', {name:result.linkName}, {reload: true});
        };

        var onSaveError = function () {
            $scope.isSaving = false;
        };

        $scope.save = function () {
            $scope.pageContent.lastUpdate = new Date();
            $scope.isSaving = true;
            if ($scope.pageContent.id !== null) {
                PageContent.update($scope.pageContent, onSaveSuccess, onSaveError);
            } else {
                PageContent.save($scope.pageContent, onSaveSuccess, onSaveError);
            }
        };

        $scope.clear = function() {
            $scope.isEditing = false;
        };

        $scope.sendMessage = function(){
            console.log($scope.message);
            ContactForm.update($scope.message);
            $scope.showForm = false;
            $scope.successMsg = true;



            $scope.message = { name: null, title: null, message: null};
        }

        // comment related code
        $scope.showCommentForm = false;
        $scope.setShowCommentForm = function(data){
            $scope.showCommentForm = data;
            $scope.showSuccessMessage = false;
        };

        $scope.reloadComments = function(){
            PageCommentByName.query({name:$scope.pageName}, function(data){
                $scope.comments = data;
            })
        }
        $scope.newComment = {author: '', comment: ''};
        $scope.postComment = function(newComment){
            newComment.commentDate = new Date();
            newComment.pageLinkName = $scope.pageName;
            PageCommentByName.create({name: $scope.pageName }, newComment, function(result){
                $scope.newComment = {author: '', comment: ''};
                $scope.showCommentForm = false;
                $scope.showSuccessMessage = true;
                $scope.reloadComments();
            })
        }
    });

ContactForm.$inject = ['$resource'];
function ContactForm ($resource, DateUtils) {
    var resourceUrl =  'api/contact-form';

    return $resource(resourceUrl, {}, {
        'get': {
            method: 'GET',
            transformResponse: function (data) {
                data = angular.fromJson(data);
                return data;
            }
        },
        'update': { method:'PUT' }
    });
}
