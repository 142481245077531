'use strict';

angular.module('isahomeApp')
    .config(function ($stateProvider) {
        $stateProvider
            .state('page', {
                parent: 'app',
                url: '/p/:name',
                data: {
                    authorities: []
                },
                views: {
                    'content@': {
                        templateUrl: 'app/page/page.html',
                        controller: 'PageController'
                    }
                }
            })
            .state('page.new', {
                parent: 'page',
                url: '/new',
                data: {
                    authorities: ['ROLE_USER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/page/page.edit.dialog.html',
                        controller: 'PageContentDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: function () {
                                return {
                                    linkName: $stateParams.name,
                                    title: null,
                                    content: null,
                                    lastUpdate: null,
                                    id: null
                                };
                            }
                        }
                    }).result.then(function() {
                        $state.go('page', null, { reload: true });
                    }, function() {
                        $state.go('page');
                    });
                }]
            })
            .state('page.delete', {
                parent: 'page',
                url: '/{id:int}/delete',
                data: {
                    authorities: ['ROLE_USER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/page-content/page-content-delete-dialog.html',
                        controller: 'PageContentDeleteController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'md',
                        resolve: {
                            entity: ['PageContent', function(PageContent) {
                                return PageContent.get({id : $stateParams.id});
                            }]
                        }
                    }).result.then(function() {
                        $state.go('page', null, { reload: true });
                    }, function() {
                        $state.go('^');
                    });
                }]
            })

            .state('file-upload-download', {
                parent: 'app',
                url: '/file/{id}',
                data: {
                    authorities: [],
                    pageTitle: 'FileUpload'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/page/file.html',
                        controller: 'FileDownloadController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    entity: ['$stateParams', 'FileUpload', function($stateParams, FileUpload) {
                        return FileUpload.get({id : $stateParams.id});
                    }]
                }
            })
    });
