'use strict';

angular.module('isahomeApp').controller('CarouselImageManagementDialogController',
    ['$scope', '$stateParams', '$uibModalInstance', 'DataUtils', 'entity', 'CarouselImage',
        function($scope, $stateParams, $uibModalInstance, DataUtils, entity, CarouselImage) {

        $scope.carouselImage = entity;
        $scope.load = function(id) {
            CarouselImage.get({id : id}, function(result) {
                $scope.carouselImage = result;
            });
        };

        var onSaveSuccess = function (result) {
            $scope.$emit('isahomeApp:carouselImageUpdate', result);
            $uibModalInstance.close(result);
            $scope.isSaving = false;
        };

        var onSaveError = function (result) {
            $scope.isSaving = false;
        };

        $scope.save = function () {
            $scope.isSaving = true;
            if ($scope.carouselImage.id != null) {
                CarouselImage.update($scope.carouselImage, onSaveSuccess, onSaveError);
            } else {
                CarouselImage.save($scope.carouselImage, onSaveSuccess, onSaveError);
            }
        };

        $scope.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

        $scope.openFile = DataUtils.openFile;

        $scope.byteSize = DataUtils.byteSize;

        $scope.setPicture = function ($file, carouselImage) {
            if ($file && $file.$error == 'pattern') {
                return;
            }
            if ($file) {
                var fileReader = new FileReader();
                fileReader.readAsDataURL($file);
                fileReader.onload = function (e) {
                    //compression
                    var image = new Image();
                    image.src = e.target.result;
                    image.onload = function(){
                        $scope.$apply(function() {
                            carouselImage.picture = resizeMe(image).substr(e.target.result.indexOf('base64,') + 'base64,'.length);
                            carouselImage.pictureContentType = $file.type;
                        });
                    };
                };
            }
        };

            function resizeMe(img) {
                var base64Start = img.src.indexOf('base64,');
                var imgType = img.src.substr(11,base64Start-11).slice(0,-1).toLowerCase();
                var compressableImageTypes = ["jpg","jpeg"];
                if(compressableImageTypes.indexOf(imgType) != -1){
                    var canvas = document.createElement('canvas');
                    var ratio = img.height / 300;
                    var width = img.width / ratio;
                    var height = img.height / ratio;
                    // resize the canvas and draw the image data into it
                    canvas.width = width;
                    canvas.height = height;
                    var ctx = canvas.getContext("2d");
                    ctx.drawImage(img, 0, 0, width, height);
                    return canvas.toDataURL("image/jpeg",0.7); // get the data from canvas as 70% JPG (can be also PNG, etc.)
                }else if(["png"].indexOf(imgType) != -1){
                    var canvas = document.createElement('canvas');
                    var ratio = img.height / 300;
                    var width = img.width / ratio;
                    var height = img.height / ratio;
                    // resize the canvas and draw the image data into it
                    canvas.width = width;
                    canvas.height = height;
                    var ctx = canvas.getContext("2d");
                    ctx.drawImage(img, 0, 0, width, height);
                    return canvas.toDataURL("image/png",0.7); // get the data from canvas as 70% JPG (can be also PNG, etc.)
                    // return img.src;
                }
            }
}]);
