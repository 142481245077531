'use strict';

angular.module('isahomeApp')
    // uncomment below to make alerts look like toast
    .config(function ($urlRouterProvider, $httpProvider, $locationProvider, $localStorageProvider,  httpRequestInterceptorCacheBusterProvider/*, AlertServiceProvider*/) {
        //AlertServiceProvider.showAsToast(true);
        $locationProvider.html5Mode({ enabled: true, requireBase: true });

        //enable CSRF
        $httpProvider.defaults.xsrfCookieName = 'CSRF-TOKEN';
        $httpProvider.defaults.xsrfHeaderName = 'X-CSRF-TOKEN';

        //Cache everything except rest api requests
        httpRequestInterceptorCacheBusterProvider.setMatchlist([/^(?!((api\/page-contents\/link\/*)|(api\/carousel-images)|(api\/main-headers)|(api\/site-headers\/main-headers))(\/|$)).*api.*/, /.*protected.*/], true);

        $urlRouterProvider.otherwise('/');

        $httpProvider.interceptors.push('errorHandlerInterceptor');
        $httpProvider.interceptors.push('authExpiredInterceptor');
        $httpProvider.interceptors.push('notificationInterceptor');
        // jhipster-needle-angularjs-add-interceptor JHipster will add new application interceptor here

        $localStorageProvider.setKeyPrefix('jhi-');
    })
    // jhipster-needle-angularjs-add-config JHipster will add new application configuration here
    .config(['$urlMatcherFactoryProvider', function($urlMatcherFactory) {
        $urlMatcherFactory.type('boolean', {
            name : 'boolean',
            decode: function(val) { return val === true || val === 'true'; },
            encode: function(val) { return val ? 1 : 0; },
            equals: function(a, b) { return this.is(a) && a === b; },
            is: function(val) { return [true,false,0,1].indexOf(val) >= 0; },
            pattern: /bool|true|0|1/
        });
    }]);
