(function() {
    'use strict';

    angular
        .module('isahomeApp')
        .controller('RegistrationDialogController', RegistrationDialogController);

    RegistrationDialogController.$inject = ['$scope', '$stateParams', '$uibModalInstance', 'entity', 'Registration'];

    function RegistrationDialogController ($scope, $stateParams, $uibModalInstance, entity, Registration) {
        var vm = this;
        vm.registration = entity;

        var onSaveSuccess = function (result) {
            $scope.$emit('isahomeApp:registrationUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        };

        var onSaveError = function () {
            vm.isSaving = false;
        };

        vm.save = function () {
            vm.isSaving = true;
            if (vm.registration.id !== null) {
                Registration.update(vm.registration, onSaveSuccess, onSaveError);
            } else {
                Registration.save(vm.registration, onSaveSuccess, onSaveError);
            }
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
    }
})();
