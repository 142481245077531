'use strict';

angular.module('isahomeApp')
    .controller('AdminCenterPagesController', function ($scope, $state, DataUtils, PageContentInfo, PageContentSearch, ParseLinks, AlertService, pagingParams, paginationConstants) {

        $scope.predicate = pagingParams.predicate;
        $scope.reverse = pagingParams.ascending;
        $scope.openFile = DataUtils.openFile;
        $scope.byteSize = DataUtils.byteSize;
        $scope.loadAll = function() {
            var onSuccess = function (data, headers) {
                $scope.links = ParseLinks.parse(headers('link'));
                $scope.totalItems = headers('X-Total-Count');
                $scope.pageContents = data;
                $scope.page = pagingParams.page;
            };
            var onError = function (error) {
                AlertService.error(error.data.message);
            };
            PageContentInfo.query({
                page: pagingParams.page - 1,
                size: paginationConstants.itemsPerPage,
                sort: [$scope.predicate + ',' + ($scope.reverse ? 'asc' : 'desc'), 'id']
            }, onSuccess, onError);
        };
        $scope.loadPage = function(page) {
            $scope.page = page;
            $scope.loadAll();
        };

        $scope.search = function () {
            PageContentSearch.query({query: $scope.searchQuery}, function(result) {
                $scope.pageContents = result;
            }, function(response) {
                if(response.status === 404) {
                    $scope.loadAll();
                }
            });
        };

        $scope.transition = function () {
            $state.transitionTo($state.$current, {
                page: $scope.page,
                sort: $scope.predicate + ',' + ($scope.reverse ? 'asc' : 'desc'),
                search: $scope.currentSearch
            });
        };

        $scope.loadAll();


    });
