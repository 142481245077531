(function() {
    'use strict';

angular.module('isahomeApp')
    .factory('User', function ($resource) {
        return $resource('api/users/:login', {}, {
                'query': {method: 'GET', isArray: true},
                'get': {
                    method: 'GET',
                    transformResponse: function (data) {
                        data = angular.fromJson(data);
                        return data;
                    }
                },
                'save': { method:'POST' },
                'update': { method:'PUT' },
                'delete':{ method:'DELETE'}
            });
        });
})();
(function() {
    'use strict';

    angular
        .module('isahomeApp')
        .factory('UserSearch', UserSearch);

    UserSearch.$inject = ['$resource'];

    function UserSearch($resource) {
        var resourceUrl =  'api/_search/users/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
