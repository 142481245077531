(function() {
    'use strict';

    angular
        .module('isahomeApp')
        .controller('SiteHeaderDialogController', SiteHeaderDialogController);

    SiteHeaderDialogController.$inject = ['$scope', '$stateParams', '$uibModalInstance', 'entity', 'SiteHeader', 'PageContentInfo'];

    function SiteHeaderDialogController ($scope, $stateParams, $uibModalInstance, entity, SiteHeader, PageContentInfo) {
        var vm = this;
        vm.siteHeader = entity;
        vm.siteheaders = SiteHeader.query({size:300});
        vm.pagecontents = PageContentInfo.query({size:300});
        vm.load = function(id) {
            SiteHeader.get({id : id}, function(result) {
                vm.siteHeader = result;
            });
        };

        var onSaveSuccess = function (result) {
            $scope.$emit('isahomeApp:siteHeaderUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        };

        var onSaveError = function () {
            vm.isSaving = false;
        };

        vm.save = function () {
            if (vm.siteHeader.id === vm.siteHeader.mainHeaderId) {
                alert('Error: Can not create a Site Header with a Parent Header of itself')
                return;
            }
            vm.isSaving = true;
            if (vm.siteHeader.id !== null) {
                SiteHeader.update(vm.siteHeader, onSaveSuccess, onSaveError);
            } else {
                SiteHeader.save(vm.siteHeader, onSaveSuccess, onSaveError);
            }
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
    }
})();
