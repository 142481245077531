/* globals $ */
'use strict';

angular.module('isahomeApp')
    .directive('adminMenuBar', function () {
        return {
            replace: true,
            restrict: 'E',
            template: '<div id="adminMenuBar">' +
            '<button class="btn btn-info" ui-sref="adminHeaders">Headers</button> ' +
            '<button class="btn btn-info" ui-sref="adminPages">Pages</button> ' +
            '<button class="btn btn-info" ui-sref="carousel-image-management">Carousel</button> ' +
            '<button class="btn btn-info" ui-sref="adminVideos">Videos</button> ' +
            '<button class="btn btn-info" ui-sref="registration">Registrations</button> ' +
            '<button class="btn btn-info" ui-sref="searchReset">Reset Search Index</button> ' +
            '<br />' +
            '<button class="btn btn-info" ui-sref="user-management">Users</button> ' +
            '<button class="btn btn-info" ui-sref="user-profile">Manage Directory</button> ' +
            '<button class="btn btn-info" ui-sref="flipbooks">Flipbooks</button> ' +
            '<button class="btn btn-info" ui-sref="file-upload">File Uploads</button> ' +
            '<button class="btn btn-info" ui-sref="unapproved-comments">Unapproved Comments</button> ' +
            '</div>'
        };
    });
