'use strict';

angular.module('isahomeApp')
    .controller('UnapprovedCommentDetailController', function ($scope, $rootScope, $stateParams, DataUtils, entity, PageComment, PageCommentApprovalUpdate) {
        $scope.pageComment = entity;
        $scope.load = function (id) {
            PageComment.get({id: id}, function(result) {
                $scope.pageComment = result;
            });
        };
        var unsubscribe = $rootScope.$on('isahomeApp:pageCommentUpdate', function(event, result) {
            $scope.pageComment = result;
        });
        $scope.$on('$destroy', unsubscribe);

        $scope.byteSize = DataUtils.byteSize;
        $scope.approveComment = function (status) {
            PageCommentApprovalUpdate.update({id: $stateParams.id, status: status}, {}, function (resp) {
                $scope.load($stateParams.id)
            }, function (err) {
                $scope.error = true;
            })
        }
    });
