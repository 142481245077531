(function() {
    'use strict';

    angular
        .module('isahomeApp')
        .controller('RegistrationDialogNewController', RegistrationDialogNewController);

    RegistrationDialogNewController.$inject = ['$scope', '$state', '$stateParams', 'entity', 'Registration'];

    function RegistrationDialogNewController ($scope, $state, $stateParams, entity, Registration) {
        var vm = this;
        vm.registration = entity;
        vm.mathA = Math.floor(Math.random() * 11);
        vm.mathB = Math.floor(Math.random() * 11);
        vm.phoneCell = '';
        vm.load = function(id) {
            Registration.get({id : id}, function(result) {
                vm.registration = result;
            });
        };

        var onSaveSuccess = function (result) {
            $scope.$emit('isahomeApp:registrationUpdate', result);
            vm.isSaving = false;
            $state.go('registration-success');
        };

        var onSaveError = function () {
            vm.isSaving = false;
        };

        vm.save = function () {
            vm.error = null;
            if (vm.mathA + vm.mathB !== parseInt(vm.phoneCell, 10)) {
                vm.error = 'Incorrect answer';
                return;
            }
            vm.isSaving = true;
            if (vm.registration.id !== null) {
                Registration.update(vm.registration, onSaveSuccess, onSaveError);
            } else {
                Registration.save(vm.registration, onSaveSuccess, onSaveError);
            }
        };
    }
})();
