(function() {
    'use strict';

    angular
        .module('isahomeApp')
        .controller('RegistrationDetailController', RegistrationDetailController);

    RegistrationDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'Registration'];

    function RegistrationDetailController($scope, $rootScope, $stateParams, entity, Registration) {
        var vm = this;
        vm.registration = entity;
        
        var unsubscribe = $rootScope.$on('isahomeApp:registrationUpdate', function(event, result) {
            vm.registration = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
