'use strict';

angular.module('isahomeApp')
    .controller('AdminCenterVideosUploadController', function ($scope, $state, $window, PageContent, SiteHeader, MainSiteHeader) {
        $scope.videoTitle = "Title";
        $scope.errorMessage = "";
        $scope.error=false;
        $scope.uploaded=false;
        $scope.success=false;
        $scope.videoId='';
        $scope.reloadPage= function () {
            $window.location.reload();
        };

        $scope.$on('event:youtube-video-uploaded', function (event, info) {
            console.log(event);
            $scope.videoId = info.id;
            $scope.uploaded=true;
            $scope.$apply();

        });
        $scope.$on('event:youtube-video-failed', function (event, info) {
           console.log(event);
            $scope.errorMessage = info.message;
            $scope.uploaded=false;
            $scope.error = true;
            $scope.videoId = null;
            $scope.$apply();
        });
        $scope.$on('event:youtube-video-processed', function (event, info) {
           console.log(event);
            console.log(info);
            $scope.videoId = info.id;
            $scope.uploaded=false;
            $scope.success=true;

            var newPage = {};
            newPage.title = $scope.videoTitle;
            newPage.content = '<logged-in-only/><iframe width="420" height="315" src="https://www.youtube.com/embed/'+ info.id +'" frameborder="0" allowfullscreen></iframe>'
            // create a page
            PageContent.update(newPage, function(result){
                $scope.newPageId = result.id;
                result.linkName = result.id;
                PageContent.update(result, function(result){
                    console.log(result);
                    $state.go('page', {name: result.id});
                });
            });
            // open the youtube video

            $scope.$apply();
        });
    });
