(function() {
    'use strict';

    angular
        .module('isahomeApp')
        .controller('PageContentDeleteController',PageContentDeleteController);

    PageContentDeleteController.$inject = ['$uibModalInstance', 'entity', 'PageContent'];

    function PageContentDeleteController($uibModalInstance, entity, PageContent) {
        var vm = this;
        vm.pageContent = entity;
        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        vm.confirmDelete = function (id) {
            PageContent.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };
    }
})();
