(function() {
    'use strict';

    angular
        .module('isahomeApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('unapproved-comments', {
            parent: 'adminCenter',
            url: '/unapproved-comments?page&sort&search',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'Unapproved Comments'
            },
            views: {
                'content@': {
                    templateUrl: 'app/admin/center/unapproved-comments/pageComment.html',
                    controller: 'UnapprovedCommentController',
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,desc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
            }
        })
        .state('unapproved-comments.detail', {
            parent: 'unapproved-comments',
            url: '/{id}',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'Unapproved Comment'
            },
            views: {
                'content@': {
                    templateUrl: 'app/admin/center/unapproved-comments/pageComment-detail.html',
                    controller: 'UnapprovedCommentDetailController'
                }
            },
            resolve: {
                entity: ['$stateParams', 'PageComment', function($stateParams, PageComment) {
                    return PageComment.get({id : $stateParams.id});
                }]
            }
        })
        .state('unapproved-comments.edit', {
            parent: 'unapproved-comments',
            url: '/{id:int}/edit',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/admin/center/unapproved-comments/pageComment-dialog.html',
                    controller: 'UnapprovedCommentDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['PageComment', function(PageComment) {
                            return PageComment.get({id : $stateParams.id});
                        }]
                    }
                }).result.then(function() {
                    $state.go('^');
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('unapproved-comments.delete', {
            parent: 'unapproved-comments',
            url: '/{id:int}/delete',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/admin/center/unapproved-comments/pageComment-delete-dialog.html',
                    controller: 'UnapprovedCommentDeleteController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                        entity: ['PageComment', function(PageComment) {
                            return PageComment.get({id : $stateParams.id});
                        }]
                    }
                }).result.then(function() {
                    $state.go('^');
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
