'use strict';

angular.module('isahomeApp')
    .controller('UnapprovedCommentController', function ($scope, $rootScope, $state, DataUtils, PageComment, PageCommentSearch, ParseLinks, PageCommentApproval, PageCommentApprovalUpdate) {
        $scope.pageComments = [];
        $scope.predicate = 'id';
        $scope.reverse = true;
        $scope.approved = false;
        $scope.page = 1;
        $scope.loadAll = function() {
            PageCommentApproval.query({isApproved: $scope.approved, page: $scope.page - 1, size: 20, sort: [$scope.predicate + ',' + ($scope.reverse ? 'asc' : 'desc'), 'id']}, function(result, headers) {
                $scope.links = ParseLinks.parse(headers('link'));
                $scope.totalItems = headers('X-Total-Count');
                $scope.pageComments = result;
            });
        };
        $scope.loadPage = function(page) {
            $scope.page = page;
            $scope.loadAll();
        };
        $scope.loadAll();


        $scope.search = function () {
            PageCommentSearch.query({query: $scope.searchQuery}, function(result) {
                $scope.pageComments = result;
            }, function(response) {
                if(response.status === 404) {
                    $scope.loadAll();
                }
            });
        };

        $scope.refresh = function () {
            $scope.loadAll();
            $scope.clear();
        };

        $scope.clear = function () {
            $scope.pageComment = {
                author: null,
                comment: null,
                commentDate: null,
                pageLinkName: null,
                approved: null,
                id: null
            };
        };

        $scope.abbreviate = DataUtils.abbreviate;

        $scope.byteSize = DataUtils.byteSize;
        $scope.toggleApproved = function () {
            $scope.approved = !$scope.approved;
            $scope.loadAll()
        }
        $scope.approveComment = function (id) {
            PageCommentApprovalUpdate.update({id: id, status: !$scope.approved}, {}, function (resp) {
                $scope.loadAll()
            }, function (err) {
                $scope.error = true;
            })
        }
        var unsubscribe = $rootScope.$on('isahomeApp:pageCommentUpdate', function() {
            $scope.loadAll();
        });
        $scope.$on('$destroy', unsubscribe);

    });
