'use strict';

angular.module('isahomeApp')
    .controller('HomeController', function ($scope, $sce, Principal, LoginService, PageContentByName, PageContent, CarouselImage, $state) {
        function getAccount() {
            Principal.identity().then(function(account) {
                $scope.account = account;
                $scope.isAuthenticated = Principal.isAuthenticated;
            });
        }
        getAccount();

        $scope.isEditing = false;
        $scope.myInterval = 5000;

        $scope.$on('authenticationSuccess', function() {
            getAccount();
        });

        $scope.login = LoginService.open;


        PageContentByName.get({name:'home'}, function(result){
            $scope.pageContent = result;
            $scope.pageContent.trustedContent = $sce.trustAsHtml(result.content);

        });


        $scope.edit = function(){
            $scope.isEditing = true;
        };
        var onSaveSuccess = function (result) {
            $scope.$emit('isahomeApp:pageContentUpdate', result);
            $scope.isSaving = false;
            $scope.isEditing = false;
            $state.go('home', {}, {reload: true});
        };

        var onSaveError = function () {
            $scope.isSaving = false;
        };

        $scope.save = function () {
            $scope.pageContent.lastUpdate = new Date();
            $scope.isSaving = true;
            if ($scope.pageContent.id !== null) {
                PageContent.update($scope.pageContent, onSaveSuccess, onSaveError);
            } else {
                PageContent.save($scope.pageContent, onSaveSuccess, onSaveError);
            }
        };

        $scope.clear = function() {
            $scope.isEditing = false;
        };

        CarouselImage.query({ page: 0, size: 20, sort: ['orderNum' + ',' + ($scope.reverse ? 'asc' : 'desc'), 'id'] },
            function(images){
               $scope.carouselImages = images;
            });
    });
