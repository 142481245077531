'use strict';

angular.module('isahomeApp')
    .controller('PageCommentDetailController', function ($scope, $rootScope, $stateParams, DataUtils, entity, PageComment) {
        $scope.pageComment = entity;
        $scope.load = function (id) {
            PageComment.get({id: id}, function(result) {
                $scope.pageComment = result;
            });
        };
        var unsubscribe = $rootScope.$on('isahomeApp:pageCommentUpdate', function(event, result) {
            $scope.pageComment = result;
        });
        $scope.$on('$destroy', unsubscribe);

        $scope.byteSize = DataUtils.byteSize;
    });
