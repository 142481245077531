'use strict';

angular.module('isahomeApp').controller('UnapprovedCommentDialogController',
    ['$scope', '$stateParams', '$uibModalInstance', 'DataUtils', 'entity', 'PageComment',
        function($scope, $stateParams, $uibModalInstance, DataUtils, entity, PageComment) {

        $scope.pageComment = entity;
        $scope.load = function(id) {
            PageComment.get({id : id}, function(result) {
                $scope.pageComment = result;
            });
        };

        var onSaveSuccess = function (result) {
            $scope.$emit('isahomeApp:pageCommentUpdate', result);
            $uibModalInstance.close(result);
            $scope.isSaving = false;
        };

        var onSaveError = function (result) {
            $scope.isSaving = false;
        };

        $scope.save = function () {
            $scope.isSaving = true;
            if ($scope.pageComment.id != null) {
                PageComment.update($scope.pageComment, onSaveSuccess, onSaveError);
            } else {
                PageComment.save($scope.pageComment, onSaveSuccess, onSaveError);
            }
        };

        $scope.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

        $scope.abbreviate = DataUtils.abbreviate;

        $scope.byteSize = DataUtils.byteSize;
        $scope.datePickerForCommentDate = {};

        $scope.datePickerForCommentDate.status = {
            opened: false
        };

        $scope.datePickerForCommentDateOpen = function($event) {
            $scope.datePickerForCommentDate.status.opened = true;
        };
}]);
