'use strict';

angular.module('isahomeApp')
    .config(function ($stateProvider) {
        $stateProvider
            .state('password', {
                parent: 'account',
                url: '/password',
                data: {
                    authorities: ['ROLE_USER'],
                    pageTitle: 'Password'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/account/password/password.html',
                        controller: 'PasswordController'
                    }
                }
            });
    });
