'use strict';

angular.module('isahomeApp')
    .controller('SearchController', function ($scope, $sce, CommentSearch, CaseStudySearch, PageContentSearch, $stateParams, LoginService, PageContentByName, PageContent, CarouselImage, $state) {

        $scope.totalResults = 0;

        PageContentSearch.query({query: $stateParams.query}, function(data){
            $scope.results = data;
            $scope.totalResults += data.length;
        });
        CaseStudySearch.query({query: $stateParams.query}, function(data){
            $scope.caseResults = data;
            $scope.totalResults += data.length;
        });
        CommentSearch.query({query: $stateParams.query}, function(data){
            $scope.commentResults = data;
            $scope.totalResults += data.length;
        });
    });
