(function() {
    'use strict';

    angular
        .module('isahomeApp')
        .controller('UserProfileDetailController', UserProfileDetailController);

    UserProfileDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'UserProfile'];

    function UserProfileDetailController($scope, $rootScope, $stateParams, entity, UserProfile) {
        var vm = this;

        vm.userProfile = entity;

        var unsubscribe = $rootScope.$on('isahomeApp:userProfileUpdate', function(event, result) {
            vm.userProfile = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
