(function() {
    'use strict';

    angular
        .module('isahomeApp')
        .controller('CaseStudyDialogController', CaseStudyDialogController);

    CaseStudyDialogController.$inject = ['$scope', '$state', 'AttachmentByCase', '$stateParams', '$uibModalInstance', 'DataUtils', 'entity', 'CaseStudy', 'Attachment', 'Comment'];

    function CaseStudyDialogController ($scope, $state, AttachmentByCase, $stateParams, $uibModalInstance, DataUtils, entity, CaseStudy, Attachment, Comment) {
        var vm = this;
        vm.caseStudy = entity;
        vm.attachments = Attachment.query();
        vm.comments = Comment.query();
        vm.load = function(id) {
            CaseStudy.get({id : id}, function(result) {
                vm.caseStudy = result;
            });
        };

        vm.attachments = [];
        vm.attachmentsToDelete = [];

        var onSaveSuccess = function (result) {
            $scope.$emit('isahomeApp:caseStudyUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
            saveAttachments(result.id);
        };

        var onSaveError = function () {
            vm.isSaving = false;
        };

        vm.save = function () {
            vm.isSaving = true;
            if (vm.caseStudy.id !== null) {
                CaseStudy.update(vm.caseStudy, onSaveSuccess, onSaveError);
            } else {
                CaseStudy.save(vm.caseStudy, onSaveSuccess, onSaveError);
            }
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

        vm.openFile = DataUtils.openFile;

        vm.byteSize = DataUtils.byteSize;

        vm.addNewChoice = function(itemList) {
            itemList.push({});
        };
        vm.removeChoice = function(index, itemList){
            itemList.splice(index,1);
        };

        vm.removeOldAttachment = function(index, itemList){
            //are you sure you want to remove this image?
            var attIdObject = {id: itemList[index].id}
            vm.attachmentsToDelete.push(attIdObject);
            itemList.splice(index,1);
        };


        vm.setImageFile = function ($file, attachment, index) {
            if ($file && $file.$error == 'pattern') {
                return;
            }
            if ($file) {
                var fileReader = new FileReader();
                fileReader.readAsDataURL($file);
                fileReader.onload = function (e) {
                    var base64Data = e.target.result.substr(e.target.result.indexOf('base64,') + 'base64,'.length);
                    //compression
                    var image = new Image();
                    image.src = e.target.result;
                    image.onload = function(){
                        $scope.$apply(function() {
                            attachment.image = resizeMe(image).substr(e.target.result.indexOf('base64,') + 'base64,'.length);
                            attachment.imageContentType = $file.type;
                            attachment.fileName = $file.name;
                            var tn = getThumbnail(image);
                            attachment.thumbnail = tn.substr(tn.indexOf('base64,') + 'base64,'.length);
                            attachment.thumbnailContentType = tn.substr(5, tn.indexOf('base64,')-6);
                        });
                    };


                };
            }
        };
        function saveAttachments(id){
            var caseId = id;

            //remove old deleted attachments
            angular.forEach(vm.attachmentsToDelete, function(index){
                Attachment.delete(index, function(result){
                    console.log("Deleted attachment with id: " + index.id)
                });
            });


            vm.totalSaved = vm.attachments.length;
            vm.saved = 0;
            angular.forEach(vm.attachments, function(att){
                att.caseStudyId = id;
                if(att.image){

                    Attachment.update(att, function(result){
                            att.id = result.id;
                            vm.saved += 1;
                            if(vm.totalSaved == vm.saved){
                                $state.go('forum.view', {id: caseId}, {reload: true})
                            }
                        },
                        function(err){
                            console.log(err);
                            vm.saved += 1;
                            if(vm.totalSaved == vm.saved){
                                $state.go('forum.view', {id: caseId}, {reload: true})
                            }
                        })
                }else{
                    vm.saved += 1;
                    if(vm.totalSaved == vm.saved){
                        $state.go('forum.view', {id: caseId}, {reload: true})
                    }
                }

            })
        }

        function resizeMe(img) {
            var base64Start = img.src.indexOf('base64,');
            var imgType = img.src.substr(11,base64Start-11).slice(0,-1).toLowerCase();
            var compressableImageTypes = ["jpg","jpeg"];
            if(compressableImageTypes.indexOf(imgType) != -1){
                var canvas = document.createElement('canvas');
                var width = img.width;
                var height = img.height;
                // resize the canvas and draw the image data into it
                canvas.width = width;
                canvas.height = height;
                var ctx = canvas.getContext("2d");
                ctx.drawImage(img, 0, 0, width, height);
                return canvas.toDataURL("image/jpeg",0.7); // get the data from canvas as 70% JPG (can be also PNG, etc.)
            }else{
                return img.src;
            }
        }
        function getThumbnail(img) {
            var base64Start = img.src.indexOf('base64,');
            var imgType = img.src.substr(11,base64Start-11).slice(0,-1).toLowerCase();
            var canvas = document.createElement('canvas');
            var ratio = img.height / 250;
            var width = img.width / ratio;
            var height = img.height / ratio;
            // resize the canvas and draw the image data into it
            canvas.width = width;
            canvas.height = height;
            var ctx = canvas.getContext("2d");
            ctx.drawImage(img, 0, 0, width, height);
            return canvas.toDataURL("image/jpeg",0.7); // get the data from canvas as 70% JPG (can be also PNG, etc.)
        }

        AttachmentByCase.query({id: $stateParams.id},function(data){
            vm.oldAttachments = data;
        })
    }
})();
