(function() {
    'use strict';

    angular
        .module('isahomeApp')
        .factory('SiteHeader', SiteHeader);

    SiteHeader.$inject = ['$resource', 'DateUtils'];

    function SiteHeader ($resource, DateUtils) {
        return $resource('api/site-headers/:id', {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }
})();
(function() {
    'use strict';

    angular
        .module('isahomeApp')
        .factory('MainSiteHeader', SiteHeader);

    SiteHeader.$inject = ['$resource', 'DateUtils'];

    function SiteHeader ($resource, DateUtils) {
        return $resource('api/site-headers/main-headers', {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
