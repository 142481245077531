(function() {
    'use strict';

    angular
        .module('isahomeApp')
        .controller('AttachmentDialogController', AttachmentDialogController);

    AttachmentDialogController.$inject = ['$scope', '$stateParams', '$uibModalInstance', 'DataUtils', 'entity', 'Attachment', 'CaseStudy'];

    function AttachmentDialogController ($scope, $stateParams, $uibModalInstance, DataUtils, entity, Attachment, CaseStudy) {
        var vm = this;
        vm.attachment = entity;
        vm.casestudys = CaseStudy.query();
        vm.load = function(id) {
            Attachment.get({id : id}, function(result) {
                vm.attachment = result;
            });
        };

        var onSaveSuccess = function (result) {
            $scope.$emit('isahomeApp:attachmentUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        };

        var onSaveError = function () {
            vm.isSaving = false;
        };

        vm.save = function () {
            vm.isSaving = true;
            if (vm.attachment.id !== null) {
                Attachment.update(vm.attachment, onSaveSuccess, onSaveError);
            } else {
                Attachment.save(vm.attachment, onSaveSuccess, onSaveError);
            }
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

        vm.openFile = DataUtils.openFile;

        vm.byteSize = DataUtils.byteSize;

        vm.setImage = function ($file, attachment) {
            if ($file && $file.$error == 'pattern') {
                return;
            }
            if ($file) {
                var fileReader = new FileReader();
                fileReader.readAsDataURL($file);
                fileReader.onload = function (e) {
                    var base64Data = e.target.result.substr(e.target.result.indexOf('base64,') + 'base64,'.length);
                    $scope.$apply(function() {
                        attachment.image = base64Data;
                        attachment.imageContentType = $file.type;
                    });
                };
            }
        };

        vm.setThumbnail = function ($file, attachment) {
            if ($file && $file.$error == 'pattern') {
                return;
            }
            if ($file) {
                var fileReader = new FileReader();
                fileReader.readAsDataURL($file);
                fileReader.onload = function (e) {
                    var base64Data = e.target.result.substr(e.target.result.indexOf('base64,') + 'base64,'.length);
                    $scope.$apply(function() {
                        attachment.thumbnail = base64Data;
                        attachment.thumbnailContentType = $file.type;
                    });
                };
            }
        };
    }
})();
