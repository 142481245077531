'use strict';

angular.module('isahomeApp')
    .controller('AdminCenterVideosController', function ($scope, $state, $http, SiteHeader, MainSiteHeader) {
        // $scope.videoTitle = "Title";
        // $scope.error=false;
        // $scope.uploaded=false;
        // $scope.success=false;
        // $scope.videos = [];
        // $scope.username = 'UCb2yAi4ioIoBLZV616mAmtw';
        // $scope.apiKey='AIzaSyDDfcLmL4n-tOIm-I1yUd-O5jQIVBznPfY';
        //
        // $scope.$on('event:youtube-video-uploaded', function (event) {
        //     console.log(event);
        //     $scope.uploaded=true;
        //     $scope.$apply();
        //
        // });
        // $scope.$on('event:youtube-video-failed', function (event) {
        //    console.log(event);
        //     $scope.uploaded=false;
        //     $scope.error = true;
        //     $scope.$apply();
        // });
        // $scope.$on('event:youtube-video-processed', function (event) {
        //    console.log(event);
        //     $scope.uploaded=false;
        //     $scope.success=true;
        //     $scope.$apply();
        // });
        //
        // $http.get('https://www.googleapis.com/youtube/v3/channels?part=contentDetails&forUsername=' + $scope.username + '&key=' + $scope.apiKey)
        //     .success(function(result){
        //         var uploadsId = result.items[0].contentDetails.relatedPlaylists.uploads;
        //         $http.get('https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&playlistId='+ uploadsId +'&key=' + $scope.apiKey)
        //             .success(function(result){
        //                 angular.forEach(result.items, function(data){
        //                     var item = [data.snippet.resourceId.videoId, data.snippet.thumbnails.default];
        //                     $scope.videos.push(item);
        //                 });
        //             })
        //             .error(function(result){
        //                 console.log(result);
        //             })
        //     })
        //     .error(function(result){
        //         console.log(result);
        //     })
    });
