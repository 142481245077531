(function() {
    'use strict';

    angular
        .module('isahomeApp')
        .controller('RegistrationController', RegistrationController);

    RegistrationController.$inject = ['$scope', '$uibModal', '$state', 'UserProfile', 'Registration', 'RegistrationSearch', 'ParseLinks', 'AlertService', 'pagingParams', 'paginationConstants'];

    function RegistrationController ($scope, $uibModal, $state, UserProfile, Registration, RegistrationSearch, ParseLinks, AlertService, pagingParams, paginationConstants) {
        var vm = this;
        vm.loadAll = loadAll;
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.transition = transition;
        vm.clear = clear;
        vm.search = search;
        vm.addToDirectory = addToDirectory;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        vm.loadAll();

        function addToDirectory (registration) {
            registration.zipCode = registration.zip;
            registration.firstName = registration.firstname;
            registration.lastName = registration.lastname;
            registration.middleInitial= registration.middle;
            registration.id = null;
            $uibModal.open({
                templateUrl: 'app/entities/user-profile/user-profile-dialog.html',
                controller: 'UserProfileDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    entity: ['UserProfile', function(UserProfile) {
                        return registration;
                    }]
                }
            }).result.then(function(result) {
                // $state.go('user-profile', null, { reload: true });
            }, function() {
                // $state.go('^');
            });


            // UserProfile.update(registration, function(result){
            //     console.log(result);
            // });
        }

        function loadAll () {
            if (pagingParams.search) {
                RegistrationSearch.query({
                    query: pagingParams.search,
                    page: pagingParams.page - 1,
                    size: paginationConstants.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            } else {
                Registration.query({
                    page: pagingParams.page - 1,
                    size: paginationConstants.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            }
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.registrations = data;
                vm.page = pagingParams.page;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage (page) {
            vm.page = page;
            vm.transition();
        }

        function transition () {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }

        function search (searchQuery) {
            if (!searchQuery){
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = '_score';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function clear () {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.currentSearch = null;
            vm.transition();
        }

    }
})();
