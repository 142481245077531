(function() {
    'use strict';

    angular
        .module('isahomeApp')
        .controller('CommentDeleteController',CommentDeleteController);

    CommentDeleteController.$inject = ['$uibModalInstance', 'entity', 'Comment'];

    function CommentDeleteController($uibModalInstance, entity, Comment) {
        var vm = this;
        vm.comment = entity;
        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        vm.confirmDelete = function (id) {
            Comment.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };
    }
})();
