'use strict';

angular.module('isahomeApp')
    .config(function ($stateProvider) {
        $stateProvider
            .state('adminHeaders', {
                parent: 'adminCenter',
                url: '/headers',
                data: {
                    authorities: ['ROLE_ADMIN'],
                    pageTitle: 'Header Management'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/admin/center/headers/admin.center.headers.html',
                        controller: 'AdminCenterHeadersController'
                    }
                }
            })
            .state('adminHeaders.new', {
                parent: 'adminHeaders',
                url: '/new',
                data: {
                    authorities: ['ROLE_ADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/admin/center/headers/admin.center.header.dialog.html',
                        controller: 'SiteHeaderDialogController',
                        controllerAs: 'vm',
                        size: 'lg',
                        resolve: {
                            entity: function () {
                                return {
                                    name: null,
                                    orderNum: null,
                                    id: null
                                };
                            }
                        }
                    }).result.then(function() {
                        $state.go('adminHeaders', null, { reload: true });
                    }, function() {
                        $state.go('adminHeaders');
                    });
                }]
            })
            .state('adminHeaders.edit', {
                parent: 'adminHeaders',
                url: '/{id:int}/edit',
                data: {
                    authorities: ['ROLE_ADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/admin/center/headers/admin.center.header.dialog.html',
                        controller: 'SiteHeaderDialogController',
                        controllerAs: 'vm',
                        size: 'lg',
                        resolve: {
                            entity: ['SiteHeader', function(SiteHeader) {
                                return SiteHeader.get({id : $stateParams.id});
                            }]
                        }
                    }).result.then(function() {
                        $state.go('adminHeaders', null, { reload: true });
                    }, function() {
                        $state.go('^');
                    });
                }]
            })
            .state('adminHeaders.delete', {
                parent: 'adminHeaders',
                url: '/{id:int}/delete',
                data: {
                    authorities: ['ROLE_ADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/site-header/site-header-delete-dialog.html',
                        controller: 'SiteHeaderDeleteController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['SiteHeader', function(SiteHeader) {
                                return SiteHeader.get({id : $stateParams.id});
                            }]
                        }
                    }).result.then(function() {
                        $state.go('adminHeaders', null, { reload: true });
                    }, function() {
                        $state.go('^');
                    });
                }]
            });
    });
