(function() {
    'use strict';

    angular
        .module('isahomeApp')
        .controller('CaseStudyDetailController', CaseStudyDetailController);

    CaseStudyDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'DataUtils', 'entity', 'CaseStudy', 'Attachment', 'Comment'];

    function CaseStudyDetailController($scope, $rootScope, $stateParams, DataUtils, entity, CaseStudy, Attachment, Comment) {
        var vm = this;
        vm.caseStudy = entity;
        vm.load = function (id) {
            CaseStudy.get({id: id}, function(result) {
                vm.caseStudy = result;
            });
        };
        var unsubscribe = $rootScope.$on('isahomeApp:caseStudyUpdate', function(event, result) {
            vm.caseStudy = result;
        });
        $scope.$on('$destroy', unsubscribe);

        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;
    }
})();
