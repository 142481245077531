'use strict';

angular.module('isahomeApp')
    .config(function ($stateProvider) {
        $stateProvider
            .state('adminVideos', {
                parent: 'adminCenter',
                url: '/videos',
                data: {
                    authorities: ['ROLE_ADMIN'],
                    pageTitle: 'Video Management'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/admin/center/videos/admin.center.videos.html',
                        controller: 'AdminCenterVideosController'
                    }
                }
            })
            .state('adminVideos.upload', {
                parent: 'adminVideos',
                url: '/upload',
                data: {
                    authorities: ['ROLE_ADMIN'],
                    pageTitle: 'Upload Video'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/admin/center/videos/admin.center.videos.upload.html',
                        controller: 'AdminCenterVideosUploadController'
                    }
                }
            })
    });
