'use strict';

angular.module('isahomeApp')
    .config(function ($stateProvider) {
        $stateProvider
            .state('cases', {
                parent: 'app',
                url: '/strabismus-forum',
                data: {
                    authorities: []
                },
                views: {
                    'content@': {
                        templateUrl: 'app/cases/cases.html',
                        controller: 'CasesController'
                    }
                }
            })
            .state('cases.view', {
                parent: 'cases',
                url: '/{id:int}',
                data: {
                    authorities: []
                },
                views: {
                    'content@': {
                        templateUrl: 'app/cases/cases.view.html',
                        controller: 'CasesViewController'
                    }
                }
            })
            .state('cases.new', {
                parent: 'cases',
                url: '/new',
                data: {
                    authorities: ['ROLE_USER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/case-study/case-study-dialog.html',
                        controller: 'CaseStudyDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: function () {
                                return {
                                    title: null,
                                    descr: null,
                                    id: null
                                };
                            }
                        }
                    }).result.then(function(result) {
                        $state.go('cases', {id: result.id}, { reload: true });
                    }, function() {
                        $state.go('cases');
                    });
                }]
            })
            .state('cases.edit', {
                parent: 'cases.view',
                url: '/edit',
                data: {
                    authorities: ['ROLE_USER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/case-study/case-study-dialog.html',
                        controller: 'CaseStudyDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['CaseStudy', function(CaseStudy) {
                                return CaseStudy.get({id : $stateParams.id});
                            }]
                        }
                    }).result.then(function(result) {
                        $state.go('cases.view', {id: result.id}, { reload: true });
                    }, function() {
                        $state.go('^');
                    });
                }]
            })
            .state('cases.delete', {
                parent: 'cases.view',
                url: '/delete',
                data: {
                    authorities: ['ROLE_USER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/case-study/case-study-delete-dialog.html',
                        controller: 'CaseStudyDeleteController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'md',
                        resolve: {
                            entity: ['CaseStudy', function(CaseStudy) {
                                return CaseStudy.get({id : $stateParams.id});
                            }]
                        }
                    }).result.then(function() {
                        $state.go('cases', null, { reload: true });
                    }, function() {
                        $state.go('^');
                    });
                }]
            });
    });
