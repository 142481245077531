'use strict';

angular.module('isahomeApp')
    .controller('CasesController', function ($scope, $sce, Principal, CaseStudy, PageContentByName, PageContent, CarouselImage, $state) {
        function getAccount() {
            Principal.identity().then(function(account) {
                $scope.account = account;
                $scope.isAuthenticated = Principal.isAuthenticated;
            });
        }
        getAccount();

        CaseStudy.query({ size: 100 }, function(results){
            $scope.caseStudies = results;
        })
        CaseStudy.query({ size: 10, page: 0, sort: ['id,desc'] }, function(results){
            $scope.sideBarCaseStudies = results;
        })
    });
