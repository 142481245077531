'use strict';

angular.module('isahomeApp')
    .controller('CasesViewController', function ($scope, $sce, Principal, Comment, CommentByCase, CaseStudy, $stateParams, PageContent, CarouselImage, $state, AttachmentByCase) {
        function getAccount() {
            Principal.identity().then(function(account) {
                $scope.account = account;
                $scope.isAuthenticated = Principal.isAuthenticated;
            });
        }
        getAccount();

        $scope.showCommentForm = false;
        $scope.showForm = function(data){
            $scope.showCommentForm = data;
        };


        $scope.reloadComments = function(){
            CommentByCase.query({id:$scope.case.id}, function(data){
                $scope.comments = data;
            })
        }

        CaseStudy.query({ size: 10, page: 0, sort: ['id,desc'] }, function(results){
            $scope.caseStudies = results;
        });
        CaseStudy.get({ id: $stateParams.id }, function(result){
            $scope.case = result;
            $scope.case.trustedContent = $sce.trustAsHtml(result.descr);
            $scope.case.trustedContent2 = $sce.trustAsHtml(result.descr2);
            AttachmentByCase.query({id:result.id}, function(data){
                $scope.attachments = data;
            })
            $scope.reloadComments();
        });
        $scope.newComment = {author: '', comment: ''};
        $scope.postComment = function(newComment){
            newComment.commentDate = new Date();
            newComment.caseStudyId = $scope.case.id;
            Comment.update(newComment,function(result){
                $scope.newComment = {author: '', comment: ''};
                $scope.showCommentForm = false;
                $scope.reloadComments();
            })
        }


    });
