'use strict';

angular.module('isahomeApp')
    .controller('FooterController', function ($scope, $location, $state, Auth, Principal, ENV, LoginService, MainSiteHeader) {
        $scope.isAuthenticated = Principal.isAuthenticated;
        $scope.$state = $state;
        $scope.inProduction = ENV === 'prod';

        $scope.logout = function () {
            Auth.logout();
            $state.go('home');
        };

        $scope.login = function () {
            LoginService.open();
        };

        $scope.reverse = true;
        MainSiteHeader.query({ page: 0, size: 12, sort: ['orderNum' + ',' + ($scope.reverse ? 'asc' : 'desc'), 'id'] },
            function(result){
                $scope.mainHeaders = result;
                $scope.mainHeaders.push({targetPageLinkName: 'contact-us', name: 'Contact Us'})
            })
    });
$(document).on('click','.navbar-collapse.in',function(e) {
    if( $(e.target).is('a') ) {
        $(this).collapse('hide');
    }
});
