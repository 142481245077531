(function() {
    'use strict';

    angular
        .module('isahomeApp')
        .controller('PageContentDetailController', PageContentDetailController);

    PageContentDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'DataUtils', 'entity', 'PageContent', 'SiteHeader'];

    function PageContentDetailController($scope, $rootScope, $stateParams, DataUtils, entity, PageContent, SiteHeader) {
        var vm = this;
        vm.pageContent = entity;
        vm.load = function (id) {
            PageContent.get({id: id}, function(result) {
                vm.pageContent = result;
            });
        };
        var unsubscribe = $rootScope.$on('isahomeApp:pageContentUpdate', function(event, result) {
            vm.pageContent = result;
        });
        $scope.$on('$destroy', unsubscribe);

        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;
    }
})();
