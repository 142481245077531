(function() {
    'use strict';

    angular
        .module('isahomeApp')
        .controller('CommentDetailController', CommentDetailController);

    CommentDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'DataUtils', 'entity', 'Comment', 'CaseStudy'];

    function CommentDetailController($scope, $rootScope, $stateParams, DataUtils, entity, Comment, CaseStudy) {
        var vm = this;
        vm.comment = entity;
        vm.load = function (id) {
            Comment.get({id: id}, function(result) {
                vm.comment = result;
            });
        };
        var unsubscribe = $rootScope.$on('isahomeApp:commentUpdate', function(event, result) {
            vm.comment = result;
        });
        $scope.$on('$destroy', unsubscribe);

        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;
    }
})();
