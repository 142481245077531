'use strict';

angular.module('isahomeApp')
    .controller('DirectoryController', function ($scope, pagingParams, paginationConstants, ParseLinks, UserProfile, UserProfileSearch, $localStorage, $sce, $stateParams, Principal, LoginService, PageContentByName, PageContent, CarouselImage, $state) {
        $scope.pageName = 'Member Directory';
        $scope.$storage = $localStorage;
        $scope.isEditing = false;
        $scope.state = $state;
        $scope.message = {};
        $scope.showForm = true;
        $scope.successMsg = false;

        //User Profile code
        var vm = this;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.loadAll = loadAll;
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.clear = clear;
        vm.search = search;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        vm.loadAll();

        function loadAll () {
            if (pagingParams.search) {
                UserProfileSearch.query({
                    query: pagingParams.search + '*',
                    page: pagingParams.page - 1,
                    size: paginationConstants.itemsPerPage,
                    sort: 'lastName'
                }, onSuccess, onError);
            } else {
                UserProfile.query({
                    page: pagingParams.page - 1,
                    size: paginationConstants.itemsPerPage,
                    sort: 'lastName'
                }, onSuccess, onError);
            }
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.userProfiles = data;
                vm.page = pagingParams.page;
            }
            function onError(error) {
                // AlertService.error(error.data.message);
            }
        }

        function loadPage (page) {
            vm.page = page;
            vm.transition();
        }

        function transition () {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }

        function search (searchQuery) {
            if (!searchQuery){
                // return vm.clear();
                searchQuery = " ";
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = '_score';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            pagingParams.search = searchQuery;
            vm.loadAll();
            // vm.transition();
        }

        function clear () {
            vm.searchQuery = null;
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.currentSearch = null;
            // vm.transition();
            vm.search();
        }




        //get directory page content and correct sidebars

        PageContentByName.get({name:$scope.pageName}, function(result){
            $scope.pageContent = result;
            $scope.pageContent.trustedContent = $sce.trustAsHtml(result.content);
            if($scope.pageContent.siteHeaders.length > 0){
                var header = $scope.pageContent.siteHeaders[0];
                $scope.topHeaderName = header.topHeaderName;
                $scope.topHeaderLinkName = header.topHeaderLinkName;
                $scope.mainHeaderName = header.mainHeaderName;
                $scope.mainHeaderLinkName = header.mainHeaderLinkName;
                $scope.subHeaderName = header.name;
                $scope.subHeaderLinkName = header.targetPageLinkName;

                var topName = header.topHeaderName || header.mainHeaderName;
                angular.forEach($scope.$storage.headers, function(header){
                    if(header.name == topName){
                        $scope.topHeader = header;
                    }
                })
            }else{
                $scope.mainHeaderName = $scope.pageContent.title;
                $scope.mainHeaderLinkName = $scope.pageContent.linkName;
            }
        }, function(error){
            $scope.pageContent = error;
            $scope.pageContent.content = $sce.trustAsHtml('<h3>No Page Found</h3>');

        });
    });
