'use strict';

angular.module('isahomeApp')
    .factory('LoginService', function ($uibModal) {
        var modalInstance = null;
        var resetModal = function () {
            modalInstance = null;
        };
        return {
            open: function () {
                if (modalInstance != null) return;
                modalInstance = $uibModal.open({
                    animation: true,
                    templateUrl: 'app/components/login/login.html',
                    controller: 'LoginController'
            });
        modalInstance.result.then(
            resetModal,
            resetModal
        );
    }
}
});
