'use strict';

angular.module('isahomeApp')
    .factory('PageContent', function ($resource, DateUtils) {
        return $resource('api/page-contents/:id', {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    data.lastUpdate = DateUtils.convertDateTimeFromServer(data.lastUpdate);
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    })
    .factory('PageContentInfo', function ($resource) {
        return $resource('api/page-contents-info', {}, {
            'query': { method: 'GET', isArray: true},
        });
    })
    .factory('PageContentByName', function ($resource, DateUtils) {
        return $resource('api/page-contents/link/:name', {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                //transformResponse: function (data) {
                //    data = angular.fromJson(data);
                //    data.lastUpdate = DateUtils.convertDateTimeFromServer(data.lastUpdate);
                //    return data;
                //}
            }
        });
    });
