(function() {
    'use strict';

    angular
        .module('isahomeApp')
        .controller('PageContentDialogController', PageContentDialogController);

    PageContentDialogController.$inject = ['$scope', '$stateParams', '$uibModalInstance', 'DataUtils', 'entity', 'PageContent', 'SiteHeader'];

    function PageContentDialogController ($scope, $stateParams, $uibModalInstance, DataUtils, entity, PageContent, SiteHeader) {
        var vm = this;
        vm.pageContent = entity;
        vm.siteheaders = SiteHeader.query({size:100});
        vm.load = function(id) {
            PageContent.get({id : id}, function(result) {
                vm.pageContent = result;
            });
        };

        var onSaveSuccess = function (result) {
            $scope.$emit('isahomeApp:pageContentUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        };

        var onSaveError = function () {
            vm.isSaving = false;
        };

        vm.save = function () {
            vm.isSaving = true;
            vm.pageContent.lastUpdate = new Date();
            if (vm.pageContent.id !== null) {
                PageContent.update(vm.pageContent, onSaveSuccess, onSaveError);
            } else {
                PageContent.save(vm.pageContent, onSaveSuccess, onSaveError);
            }
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

        vm.openFile = DataUtils.openFile;

        vm.byteSize = DataUtils.byteSize;
        vm.datePickerForLastUpdate = {};

        vm.datePickerForLastUpdate.status = {
            opened: false
        };

        vm.datePickerForLastUpdateOpen = function($event) {
            vm.datePickerForLastUpdate.status.opened = true;
        };
    }
})();
