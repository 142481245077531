'use strict';

angular.module('isahomeApp')
    .controller('AdminCenterHeadersController', function ($scope, $state, SiteHeader, MainSiteHeader) {
        // function updateSubHeaders(subheaders){
        //     for(var i = 0; i < subheaders.length; i++){
        //         subheaders[i].orderNum = i+1;
        //         SubHeader.update(subheaders[i]);
        //     }
        // }

        $scope.reverse = true;
        MainSiteHeader.query({ page: 0, size: 300, sort: ['orderNum' + ',' + ($scope.reverse ? 'asc' : 'desc'), 'id'] },
            function(result){
                $scope.mainHeaders = result;
                // angular.forEach($scope.mainHeaders, function(header){
                //     SubHeaderByMainHeader.get({id:header.id}, function(subheaders){
                //         header.subheaders = subheaders;
                //     });
                // });
            });


        $scope.mainHeaderSortOptions = {
            containerPositioning: 'relative',
            accept: function (sourceItemHandleScope, destSortableScope) {
                return sourceItemHandleScope.itemScope.sortableScope.options.containment === destSortableScope.options.containment;
             },

            itemMoved: function (event) {
                if(event.dest.sortableScope.options.containment !== event.source.sortableScope.options.containment){
                    return null;
                }
                console.log(event);
            },
            orderChanged: function () {
                //update orderNum for all mainHeaders
                // for(var i = 0; i < $scope.mainHeaders.length; i++){
                //     $scope.mainHeaders[i].orderNum = i + 1;
                //     //console.log($scope.mainHeaders[i]);
                //     MainHeader.update($scope.mainHeaders[i], function(result){
                //         if(result.orderNum === $scope.mainHeaders.length){
                //             //console.log("All main headers updated");
                //             $state.go('adminHeaders', null, { reload: true });
                //         }
                //     });
                // }
            },
            containment: '#board1'
        };
        // $scope.subHeaderSortOptions = {
        //     containerPositioning: 'relative',
        //     accept: function (sourceItemHandleScope, destSortableScope) {
        //         return sourceItemHandleScope.itemScope.sortableScope.options.containment === destSortableScope.options.containment;
        //     },
        //     itemMoved: function (event) {
        //         //update main header when moving between columns
        //         event.source.itemScope.modelValue.mainHeaderName = event.dest.sortableScope.$parent.header.name;
        //         event.source.itemScope.modelValue.mainHeaderId = event.dest.sortableScope.$parent.header.id;
        //
        //         updateSubHeaders(event.source.sortableScope.$parent.header.subheaders);
        //         updateSubHeaders(event.dest.sortableScope.$parent.header.subheaders);
        //         //$state.go('adminHeaders', null, { reload: true });
        //
        //     },
        //     orderChanged: function (event) {
        //         //update orderNum for subheaders
        //         updateSubHeaders(event.dest.sortableScope.$parent.header.subheaders);
        //         //$state.go('adminHeaders', null, { reload: true });
        //
        //     },
        //     containment: '#board'
        // };
        // $scope.addNewSubheader = function(header){
        //     console.log(header);
        // };

    });
