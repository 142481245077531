'use strict';

angular.module('isahomeApp')
    .controller('SearchResetController', function ($scope, PageContent, $http) {
        $scope.reindex = function(){
            $http.post("/api/elasticsearch/index", {}).then(function(data){
                console.log(data);
            });
        }
    });
