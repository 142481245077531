(function() {
    'use strict';

    angular
        .module('isahomeApp')
        .controller('CommentDialogController', CommentDialogController);

    CommentDialogController.$inject = ['$scope', '$stateParams', '$uibModalInstance', 'DataUtils', 'entity', 'Comment', 'CaseStudy'];

    function CommentDialogController ($scope, $stateParams, $uibModalInstance, DataUtils, entity, Comment, CaseStudy) {
        var vm = this;
        vm.comment = entity;
        vm.casestudys = CaseStudy.query();
        vm.load = function(id) {
            Comment.get({id : id}, function(result) {
                vm.comment = result;
            });
        };

        var onSaveSuccess = function (result) {
            $scope.$emit('isahomeApp:commentUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        };

        var onSaveError = function () {
            vm.isSaving = false;
        };

        vm.save = function () {
            vm.isSaving = true;
            if (vm.comment.id !== null) {
                Comment.update(vm.comment, onSaveSuccess, onSaveError);
            } else {
                Comment.save(vm.comment, onSaveSuccess, onSaveError);
            }
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

        vm.openFile = DataUtils.openFile;

        vm.byteSize = DataUtils.byteSize;
        vm.datePickerForCommentDate = {};

        vm.datePickerForCommentDate.status = {
            opened: false
        };

        vm.datePickerForCommentDateOpen = function($event) {
            vm.datePickerForCommentDate.status.opened = true;
        };
    }
})();
